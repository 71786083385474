import { useSetRecoilState, useRecoilValue } from 'recoil'
import { global } from '@store/index'
import { THEME_CLASS } from '@config'

export const THEME = THEME_CLASS

export default function useTheme () {

  const { theme } = useRecoilValue(global)
  const setGlobal = useSetRecoilState(global)
  function changeTheme (val) {
    if (theme === val) return
    setGlobal(d => ({...d, theme: val}))
  }
  function resetTheme () {
    changeTheme(null)
  }
  
  return {
    theme,
    changeTheme,
    resetTheme
  }
}