import { buildUrl } from '@plugin/http/lib/utils'
import { getTime } from '@utils'
import axios from 'axios'
import md5 from 'blueimp-md5'
import generateParams from './params'
import generateResponse from './response'
import { getApiUrl } from './utils'

export default class HTTP {

  constructor() {
    this.cfCache = true
    this.forcePublicToken = false
  }

  async #proxy(method, url, params, ops, { token, uuid } = {}) {
    const { cfCache, forcePublicToken } = this
    const [fetchUrl, p] = await generateParams({ url, method, params, ops, token, uuid, cfCache, forcePublicToken })
    const response = await fetch(fetchUrl, p)
    if (response.ok) {
      return await generateResponse(response)
    } else {
      let data = {}
      try {
        data = await response.json()
      } catch (error) { }
      console.error(new Date().toLocaleString(), 'http error', fetchUrl, 'status', response.status, 'statusText', response.statusText)
      console.trace()
      return Promise.reject({ status: response.status, statusText: response.statusText, ...data })
    }
  }

  async get(url, params, ops, apiToken) {
    return this.#proxy('get', url, params, ops, apiToken)
  }
  // get = cache(async (url, params, ops, apiToken) => {
  //   return this.#proxy('get', url, params, ops, apiToken)
  // })

  async post(url, params, ops, apiToken) {
    return this.#proxy('post', url, params, ops, apiToken)
  }

  async put(url, params, ops, apiToken) {
    return this.#proxy('put', url, params, ops, apiToken)
  }

  async delete(url, params, ops, apiToken) {
    return this.#proxy('delete', url, params, ops, apiToken)
  }

  async staticApi(url, params) {
    const now = Math.floor(Date.now() / 1000)
    const ymdhm = getTime(now, 'YYYYMMDDHHmm')
    const href = buildUrl(getApiUrl(url), undefined, params) + `${ymdhm.substring(0, ymdhm.length - 1)}`
    const jsonName = md5(href)
    return axios.get(`https://s.oneroof.co.nz/static-api-v2/${getTime(now, 'YYYYMMDD')}/${jsonName}.json`)
      .then(({ data }) => {
        return data.data
      })
      .catch(() => {
        return this.get(url, params)
      })
  }
}