import btoa from 'btoa'

export const API_BASE_CLIENT = process.env.NEXT_PUBLIC_API_URL_CLIENT
export const API_BASE_SERVER = process.env.NEXT_PUBLIC_API_URL_SERVER
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION
export const PUBLIC_TOKEN = (function defaultToken () {
  let key = 'B41n73ivbk-w0W8OyEkm1-whmnE9w66e'
  let secret = 'ps4z1a4c5J-NpDc6ujX67-YNyBgX8D7o'
  let token = btoa(key + ':' + secret)
  return `Public ${token}`
})()

export const ACCESS_CLIENT_ID = process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID
export const ACCESS_CLIENT_SECRET = process.env.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET