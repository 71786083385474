import httpClient from '@apiClient'

export default class Statistics {
  constructor() {
    this.viewed = []
  }

  static isPageAlt() {
    return window.location.search.includes('page=alt')
  }

  push(url, params, post = true, mutiple = false, fire = true) {
    if (typeof url === 'object') {
      params = url.params
      post = typeof url.post === 'boolean' ? url.post : true
      mutiple = typeof url.mutiple === 'boolean' ? url.mutiple : false
      fire = typeof url.fire === 'boolean' ? url.fire : true
      url = url.url
    }

    if (!fire || !url || this.viewed.includes(url)) return

    if (!mutiple) {
      this.viewed.push(url)
    }
    // 不需要cfcache
    httpClient.cfCache = false
    return post ? httpClient.post(url, params) : httpClient.get(url, params)
  }
}