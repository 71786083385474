'use client'

import { global } from '@store/index'
import { assign } from 'lodash-es'
import { SnackbarProvider } from 'notistack'
import { useRecoilValue } from 'recoil'

const messageConfig = {
  maxSnack: 3,
  // hideIconVariant: true,
  autoHideDuration: 2000,
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom'
  },
  style: {
    borderRadius: '5px',
    justifyContent: 'center',
    fontSize: 16,
    boxShadow: 'none',
    fontWeight: 'bold',
  },
  iconVariant: {
    success: <i className='icon icon-ok mr-8 text-greenrgb !text-2xl' />,
    fail: <i className='icon icon-ok mr-8 text-redrgb !text-2xl' />,
  }
}

export default function ToastProvider({
  children
}) {
  const { isMobile } = useRecoilValue(global)

  return (
    <SnackbarProvider {...(assign(messageConfig, { dense: isMobile }))}>
      {children}
    </SnackbarProvider>
  )
}