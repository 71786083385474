import { IS_SERVER } from '@config'
import { Base64 } from 'js-base64'
import JsSHA from 'jssha'
import { ACCESS_CLIENT_ID, ACCESS_CLIENT_SECRET, API_BASE_CLIENT, API_BASE_SERVER, API_VERSION, PUBLIC_TOKEN } from './config'

/**
 * 生成一个时间戳，需要去掉分钟、秒、毫秒
 * @returns timestamp
 */
export function getTimestamp() {
  if (!IS_SERVER) return Date.now()
  const date = new Date()
  date.setSeconds(0)
  date.setMinutes(0)
  date.setMilliseconds(0)
  return date.getTime()
}

/**
 * 生成接口需要的headers
 * @returns headers
 */
export function getApiHeaders(url, { uuid, token } = {}, forcePublicToken = false) {
  const timestamp = process.env.API_TIMESTAMP || Date.now()
  return Object.assign(
    {
      'Timestamp': timestamp,
      'Sign': process.env.API_SIGN || signUrl(url, timestamp),
      'Authorization': !forcePublicToken && token ? `User ${token}` : PUBLIC_TOKEN,
      'Content-Type': 'application/json',
      'Client': 'web',
    },
    uuid && { 'User-UUID': uuid },
    ACCESS_CLIENT_ID && { 'CF-Access-Client-Id': ACCESS_CLIENT_ID },
    ACCESS_CLIENT_SECRET && { 'CF-Access-Client-Secret': ACCESS_CLIENT_SECRET })
}

/**
 * url签名计算
 * @param {string} url
 * @param {number} timestamp
 * @returns sign
 */
export function signUrl(url, timestamp) {
  url = `${url}`.split('')
  let reg = /[a-z0-9]/i
  let urlMap = {}
  let urlList = []

  url.forEach(item => {
    if (reg.test(item)) {
      if (urlMap[item]) {
        urlMap[item]++
      } else {
        urlMap[item] = 1
        urlList.push(item)
      }
    }
  })

  let str = ''
  urlList.forEach(key => {
    str += `${key}${urlMap[key]}`
  })
  if (timestamp) {
    str += timestamp
  } else {
    str += new Date().getTime()
  }
  const shaObj = new JsSHA('SHA-256', 'TEXT', { encoding: 'UTF8' })
  shaObj.update(str)
  return shaObj.getHash('HEX')
}

/**
 * 
 * @param {string} url api address
 * @returns 
 */
export function getApiUrl(url) {
  if (/^https?/i.test(url)) return url
  const base = IS_SERVER ? API_BASE_SERVER : API_BASE_CLIENT
  return `${base}/${API_VERSION}/${url}`.replace(/\/\/\/?/g, '/').replace(/(https?:)\/([a-z])/i, '$1//$2')
}

export function buildUrl(url, params, query, hash) {
  if (!url) return url

  const originHash = `${url}`.includes('#') ? url.replace(/[^#]+(#.*)/, '$1') : null
  if (params) {
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        url = url.replace(`{${key}}`, params[key])
      }
    }
  }
  if (query) {
    let list = []
    for (const key in query) {
      if (Object.hasOwnProperty.call(query, key)) {
        list.push([key, query[key]].join('='))
      }
    }
    if (list.length > 0) url = `${url.replace(/#.*$/, '')}${url.indexOf('?') === -1 ? '?' : '&'}${list.join('&')}`
    if (originHash) url = `${url}${originHash}`
  }
  if (hash) url = originHash ? url.replace(/#.*$/, `#${hash}`) : `${url}#${hash}`
  return url
}

export function decodeToken(token) {
  return Base64.decode(token.split('.')[1])
}
