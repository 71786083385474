import httpClient from '@apiClient'
import { USER_INFO_KEY, USER_REFRESH_TOKEN_KEY, USER_TOKEN_KEY, UUID_KEY } from '@auth/config'
import { decodeToken } from '@plugin/http/lib/utils'
import { object2camelCase } from '@utils'
import Cookies from 'js-cookie'
import { assign, get, pick } from 'lodash-es'


/**
 * 登录
 * @param {object} data {username, password}
 * @param {string} signupSource signupSource
 * @param {number} forceAddSignSource 强制添加signupSource 0|1
 * @param {boolean} remember 
 */
export async function signin(data, signupSource, forceAddSignSource, remember) {
  const res = await httpClient.post('token/self', assign(forceAddSignSource ? { signupSource, forceAddSignSource } : undefined, pick(data, ['username', 'password'])))
  if (res.token) {
    saveToken(res, remember)
    await getUserUUID()
  }
  return res
}

/**
 * google登录
 * @param {string} token token
 * @param {string} signupSource signupSource
 * @param {number} forceAddSignSource 强制添加signupSource 0|1
 * @param {*} remember 
 */
export async function signinWithGoogle(token, signupSource, forceAddSignSource = 0, remember) {
  const res = await httpClient.post('google/no-password', { token, signupSource, forceAddSignSource })
  saveToken(res, remember)
  await getUserUUID()
  return res
}

/**
 * facebook登录
 * @param {string} openId token
 * @param {string} signupSource signupSource
 * @param {number} forceAddSignSource 强制添加signupSource 0|1
 * @param {*} remember 
 */
export async function signinWithFacebook(openId, signupSource, forceAddSignSource = 0, remember) {
  const res = await httpClient.post('facebook', { openId, signupSource, forceAddSignSource })
  if (res.token) {
    saveToken(res, remember)
    await getUserUUID()
  }
  return res
}

/**
 * 创建facebook账号
 * @param {object} params {openId,account,email,firstName,lastName,avatar,isReceiveBizEmail,signupSource}
 */
export async function signupWithFacebook(params) {
  const res = await httpClient.post('facebook/email', params)
  if (res.token) {
    saveToken(res)
    await getUserUUID()
  }
  return res
}

/**
 * 已有账号绑定facebook
 * @param {object} params {openId,account,email,password}
 */
export async function bindWithFacebook(params) {
  const res = await httpClient.put('facebook/email', params)
  if (res.token) {
    saveToken(res)
    await getUserUUID()
  }
  return res
}

/**
 * 注册
 * @param {object} data {firstName, email, password, isReceiveBizEmail}
 * @param {boolean} isCommercial
 * @param {boolean} remember 
 */
export async function signup(data, isCommercial, remember) {
  const res = await httpClient.post('user', assign(isCommercial ? { isCommercial: 1 } : undefined, pick(data, ['firstName', 'email', 'password', 'isReceiveBizEmail', 'signupSource'])))
  saveToken(res, remember)
  await getUserUUID()
  return res
}

/**
 * 生成prospect user
 * @param {object} params {email, signupSource, isReceiveBizEmail, tvnzMarketing, customMarketingOpt, registeredCheck}
 */
export async function prospectUser({ email, firstName, signupSource, isReceiveBizEmail, tvnzMarketing, customMarketingOpt, registeredCheck = 1 }) {
  const params = assign({ registeredCheck, email, firstName, signupSource, isReceiveBizEmail, tvnzMarketing, customMarketingOpt })
  const res = await httpClient.post('prospect', params)
  if (!getToken()) {
    saveUUID(res.uuid)
  }
  return res
}

/**
 * 获取用户信息
 */
export async function fetchUserInfo() {
  const res = await httpClient.get('user/info')
  localStorage.setItem(USER_INFO_KEY, JSON.stringify(res))
  return res
}

/**
 * 找回密码
 * @param {string} email 
 */
export async function forgotUser(email) {
  return await httpClient.post('user/password/email/forgotten', { email })
}

/**
 * 获取uuid
 */
export async function getUserUUID(cache = true) {
  const { uuid } = await httpClient.post('uuid')
  if (cache) saveUUID(uuid)
  return uuid
}

/**
 * 退出登录
 */
export function loginout() {
  Cookies.remove(USER_TOKEN_KEY)
  Cookies.remove(USER_REFRESH_TOKEN_KEY)
  localStorage.removeItem(USER_INFO_KEY)
  localStorage.removeItem('user-num')
}

/**
 * 保存token
 * @param {object} param {refreshToken, token}
 * @param {boolean} remember 
 */
export function saveToken({ refreshToken, token }, remember = true) {

  const now = new Date()
  now.setFullYear(now.getFullYear() + 1)

  // token
  Cookies.set(USER_TOKEN_KEY, token, { expires: now })
  // refresh token
  Cookies.set(USER_REFRESH_TOKEN_KEY, refreshToken, { expires: now })
  // user
  localStorage.setItem(USER_INFO_KEY, decodeToken(token))
}

/**
 * 保存uuid
 * @param {string} uuid
 */
export function saveUUID(uuid) {
  const now = new Date()
  now.setFullYear(now.getFullYear() + 1)
  Cookies.set(UUID_KEY, get(uuid, 'uuid', uuid), { expires: now })
}

/**
 * get uuid
 * @returns uuid
 */
export function getUUID() {
  return Cookies.get(UUID_KEY)
}

/**
 * get token
 * @returns token
 */
export function getToken() {
  return Cookies.get(USER_TOKEN_KEY)
}

/**
 * get refreshToken
 * @returns refreshToken
 */
export function getRefreshToken() {
  return Cookies.get(USER_REFRESH_TOKEN_KEY)
}

/**
 * get userinfo
 * @returns user info
 */
export function getUserInfo() {
  let res = null
  const info = localStorage.getItem(USER_INFO_KEY)
  if (info) {
    res = object2camelCase(JSON.parse(info))
    res.name = [res.firstName, res.lastName].join(' ')
  }
  return res
}