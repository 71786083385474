'use client'

import { RecoilRoot } from 'recoil'
// import Theme from '@component/theme'
import ToastProvider from '@component/toastProvider'
import useTheme from '@hooks/useTheme'
import statsPageView from '@plugin/statistics'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
// import RootComponent from '@component/root'

export default function StoreRoot({
  children
}) {

  return (
    <RecoilRoot>
      <ToastProvider>
        <Content>{children}</Content>
      </ToastProvider>
    </RecoilRoot>
  )
}

function Content({
  children
}) {

  // 当前页面主题样式
  const { theme } = useTheme()
  // 每个页面统计
  const pathName = usePathname()
  useEffect(() => {
    statsPageView()
  }, [pathName])

  return (
    <>
      {/* <Theme></Theme>/ */}
      <body className={theme} >
        {children}
      </body >
    </>
  )
}