import { buildUrl, getApiHeaders, getApiUrl } from './utils'

export default async function basicFetch(url, params, method = 'GET') {
  const hasBody = ['PUT', 'POST'].includes(method.toLocaleUpperCase())
  const fetchUrl = buildUrl(getApiUrl(url), undefined, hasBody ? undefined : params)
  const headers = getApiHeaders(fetchUrl)
  const response = await fetch(fetchUrl, Object.assign({
    method,
    headers,
    hasBody
  }, hasBody && params ? { body: JSON.stringify(params) } : undefined))
  if (response.ok) {
    return await response.json()
  } else {
    try {
      const d = await response.json()
      return Promise.reject(d)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
