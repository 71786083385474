import { object2camelCase } from '@utils'
import { each, size } from 'lodash-es'

const EXPOSE_HEADERS = [
  'x-total-count',
  'x-selected-count',
  'x-package',
  'x-first-total-count',
  'x-carousel-title',
  'x-offline-count',
  'x-recent-add-count',
  'x-recent-offline-count',
  'x-fav-total-count',
  'x-fav-offline-count',
  'x-fav-recent-add-count',
  'x-fav-recent-offline-count'
]
export default async function generateResponse(response) {
  let data
  const cres = response.clone()
  try {
    data = object2camelCase(await response.json())
  } catch (error) {
    if (/^2\d+$/.test(cres.status)) {
      data = {
        code: cres.status,
        message: cres.statusText
      }
    }
  }
  if (!data) return data

  /**
   * 这里很奇怪
   * 第一次读取404 response.ok = false
   * 刷新页面第二次 response.ok = true 并返回 { code: 404, message: 'Not found' }
   * 这里额外处理，如果是code === 404，则返回错误
   */
  if (data.code === 404) {
    return Promise.reject({ status: data.code, statusText: data.message })
  }

  const headers = {}
  each(EXPOSE_HEADERS, key => {
    let val = response.headers.get(key)
    if (val) {
      if (/^\d+$/.test(val)) val = parseFloat(val)
      headers[key] = val
    }
  })

  if (size(headers)) data.headers = headers
  return data
}

/**
 * 把object key由驼峰转成下划线
 * @param {object} data
 * @returns object
 */
// function object2snake(data) {
//   if (isArray(data)) {
//     return map(data, item => {
//       return object2snake(item)
//     })
//   } else if (isPlainObject(data)) {
//     let res = {}
//     forEach(data, (val, key) => {
//       res[snakeCase(key)] = object2snake(val)
//     })
//     return res
//   } else {
//     return data
//   }
// }
