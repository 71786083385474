import Statistics from './base'

const statistics = new Statistics()

export const RECORD_LOG_SOURCE = {
  view: 1,
  search: 2,
  slide: 3,
  news: 4,
}

/**
 * 每个页面统计
 */
export default function statsPageView() {
  statistics.push({
    url: 'record-log/access',
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}

/**
 * carousel 广告click统计
 */
export function statsHouseImageSlide() {
  return id => () => statistics.push(`house-image-slide-statistics/${id}`)
}

/**
 * carousel 广告view统计
 */
export function statsHouseView(carouselId, source) {
  return id => () => {
    statistics.push(`houses/stat/${id}`, { type: 'view', source, carouselId })
    statistics.push(`house-carousel-show-statistics/${id}`, { carouselId })
  }
}

/**
 * carousel 广告click统计
 */
export function statsHouseClick(carouselId, source) {
  return id => () => {
    statistics.push(`houses/stat/${id}`, { type: 'click', source, carouselId })
    statistics.push(`house-carousel-click-statistics/${id}`, { carouselId })
  }
}

/**
 * 房源详情咨询按钮点击统计
 */
export function statsEnquiryClick() {
  return id => {
    statistics.push({
      url: `house/${id}/enquiry-click`,
      post: false,
      mutiple: true
    })
  }
}

/**
 * 房源列表统计
 */
export function statsHouseList(ids, params) {
  if (!ids) return

  statistics.push({
    url: 'record-log/house-search',
    params: {
      source: RECORD_LOG_SOURCE.search,
      params: JSON.stringify(params),
      ids
    },
    post: true,
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}

/**
 * 房源详情统计
 */
export function statsHouseDetail(id) {
  if (!id) return

  statistics.push({
    url: 'record-log/house-view',
    params: {
      source: RECORD_LOG_SOURCE.view,
      ids: id
    },
    post: true,
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}

/**
 * 房源详情统计
 */
export function statsHouseViewTime(id, viewTime) {
  if (!id) return

  statistics.push({
    url: 'record-log/house_view_time',
    params: {
      viewTime,
      ids: id
    },
    post: true,
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}

/**
 * 新闻详情统计
 */
export function statsNewsDetail(id) {
  if (!id) return

  statistics.push({
    url: 'add-view-count/news',
    params: {
      objectId: id
    },
    post: true,
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}

/**
 * 中介详情统计
 */
export function statsAgentDetail(id) {
  if (!id) return

  statistics.push({
    url: 'add-view-count/agent',
    params: {
      objectId: id
    },
    post: true,
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}

/**
 * 中介公司详情统计
 */
export function statsAgencyDetail(id) {
  if (!id) return

  statistics.push({
    url: 'add-view-count/office',
    params: {
      objectId: id
    },
    post: true,
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}

/**
 * 地图坐标点显示统计
 */
export function statsMapPoint(ids, isSold = false) {
  if (!ids) return

  statistics.push({
    url: `add-view-count/${isSold ? 'transaction' : 'property'}`,
    params: {
      source: RECORD_LOG_SOURCE.view,
      ids
    },
    post: true,
    mutiple: true
  })
}

/**
 * 中介列表统计
 */
export function statsAgentList(ids, logType = 'find-agent-homepage') {
  if (!ids) return

  statistics.push({
    url: `add-view-count/${logType}`,
    params: {
      ids
    },
    post: true,
    mutiple: true,
    fire: !Statistics.isPageAlt()
  })
}