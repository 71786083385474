import { assign, mapValues, pickBy } from 'lodash-es'
import { buildUrl } from '@plugin/http/lib/utils'
import { getApiHeaders, getApiUrl } from './utils'

/**
 * 根据参数生成api params
 * @returns params
 */
export default async function generateParams({ url, method, params, headers, ops, uuid, token, cfCache, forcePublicToken = false }) {
  const hasBody = ['PUT', 'POST'].includes(method.toLocaleUpperCase())
  const isGet = method.toLocaleUpperCase() === 'GET'
  const parseParams = filterParams(cfCache && isGet ? assign({ cfcache: 'on' }, params) : params)
  const fetchUrl = buildUrl(getApiUrl(url), undefined, hasBody ? undefined : parseParams)
  const apiHeaders = getApiHeaders(fetchUrl, { uuid, token }, forcePublicToken)
  const headersObj = new Headers()
  mapValues(assign(apiHeaders, headers), (val, key) => {
    headersObj.append(key, val)
  })
  const res = assign({
    method,
    headers: headersObj,
    ...ops
  }, hasBody && parseParams ? { body: JSON.stringify(parseParams) } : undefined)
  return [fetchUrl, res]
}

/**
 * 过滤对象无效的值 null undefined, NaN
 * @param {Object} params 参数值
 * @returns params
 */
function filterParams(params) {
  return pickBy(params, v => ![null, undefined, NaN].includes(v))
}
